import React from 'react';
import ReactDOM from 'react-dom';
import { MsalApp, defaultTheme } from 'agi-ui';

const logo = `${process.env.PUBLIC_URL}/images/takeda.png`;
const geolandscapeIcon = `${process.env.PUBLIC_URL}/images/geo-landscape.svg`;
const payerlandscapeIcon = `${process.env.PUBLIC_URL}/images/payer-landscape.svg`;
const payertophcpsIcon = `${process.env.PUBLIC_URL}/images/payer-top-hcps.svg`;
const repmessagesIcon = `${process.env.PUBLIC_URL}/images/rep-messages.svg`;
const adminIcon = `${process.env.PUBLIC_URL}/images/admin.svg`;
const printOnDemandIcon = `${process.env.PUBLIC_URL}/images/pod-portal.svg`;
const sidebarLogo = `${process.env.PUBLIC_URL}/images/takeda.png`;
const excelIcon = `${process.env.PUBLIC_URL}/images/excel.svg`;
const viewMoreIcon = `${process.env.PUBLIC_URL}/images/view-more.svg`;
const arrowIcon = `${process.env.PUBLIC_URL}/images/expand.svg`;
const iconInfo = `${process.env.PUBLIC_URL}/images/icon_info.svg`;
const poweredByLogo = `${process.env.PUBLIC_URL}/images/logo_powered_by.png`;

const heatMeasures = {
    high: "#0F52BA",
    low: "#6593F5",
    medium: "#0080FF",
    veryHigh: '#003152',
    veryLow: "#95C8D8",
}
export const theme = {
  ...defaultTheme,
    css:{
      sidebarLogoHeight: '90px',
      sidebarLogoWidth:  'auto',
      sidebarLogoMarginTop: '20px',
      sidebarLogoMarginLeft: '10px'
    },
    colors: {
        ...defaultTheme.colors,
        primary: '#117DB8',
        secondary: '#5FC6FF',
        // Disclaimer colour for 'Internal reference' notice
        disclaimer: '#EAAA54',
        // Admin 'invalid input' error fields 
        warning: '#BB0000',
        // Admin Submit/clear buttons
        admin: '#4D4D4D',
        // Highlight colour for dropdowns/filters
        highlight: '#DCF0FF',
        // Selected colour for dropdowns/filters
        selected: '#C7D8E6',
        // Pull out sidebar background
        sidebar: 'linear-gradient(rgb(77, 77, 77) 15%, rgb(57, 57, 57) 81%)',
        // Rep Messages Formulary Win highlighting in HCP Top 5
        isWin: '#1052ba',

        // Filter for sidebar icons (base)
        sidebarIconFilter: '',
        // Hover filter for sidebar icons
        sidebarIconHoverFilter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)',
        // Background hover colour for sidebar icons
        sidebarIconHoverBackground: 'gray',
        // Selected filter for sidebar icons (blue)
        sidebarIconSelectedFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

        // Filter for the excel icon
        excelColorFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

        infoIconColor: heatMeasures.veryHigh,


        // Background colour for logout button
        logoutBackground: 'black linear-gradient(180deg, #4E4E4E 0%, #5B5B5B 100%) 0% 0% no-repeat padding-box',
        // Hover background colour for logout button
        logoutBackgroundHover: 'black linear-gradient(180deg, #5D5D5D 100%, #5C5C5C 0%) 0% 0% no-repeat padding-box'

        
    },
    icons: {
        geoLandscape: geolandscapeIcon,
        payerLandscape: payerlandscapeIcon,
        payerTopHcps: payertophcpsIcon,
        repMessages: repmessagesIcon,
        admin: adminIcon,
        printOnDemandPortal: printOnDemandIcon,
        logo,
        excel: excelIcon,
        viewMore: viewMoreIcon,
        expand: arrowIcon,
        sidebarLogo,
        iconInfo,
        poweredByLogo,
        logoHeader: {
            height: "3.5rem",
            width: "auto",
            padding: "1rem .25rem"
        },
        logoFooter: {
            height: "1rem",
            width: "auto"
        }
    },
    // Pie chart and heatmap colours
    // Chosen in order of appearance
    palette: [
        heatMeasures.veryHigh,
        heatMeasures.high,
        heatMeasures.medium,
        heatMeasures.low,
        heatMeasures.veryLow,
    ],

    // Rep Messages Formulary Win highlighting in HCP Top 5
    isWinEnabled: true,
    winToolTipText: "Formulary Win",

    // Rep Messages - View More header text
    viewMoreHeaderText: "Access",
    podLinks: []
};

ReactDOM.render(
  React.createElement(MsalApp, { theme: theme }, null),
  document.getElementById('root')
);
